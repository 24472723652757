@font-face {
	font-family: HelveticaNeueCyr-Medium;
	src: url('../fonts/Helvetica-Neue/HelveticaNeueCyr-Medium.eot');
	src: local('HelveticaNeueCyr'), local('HelveticaNeueCyr-Medium'),
		url('../fonts/Helvetica-Neue/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Helvetica-Neue/HelveticaNeueCyr-Medium.woff') format('woff'),
		url('../fonts/Helvetica-Neue/HelveticaNeueCyr-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: HelveticaNeueCyr-Bold;
	src: url('../fonts/Helvetica-Neue/HelveticaNeueCyr-Bold.eot');
	src: local('HelveticaNeueCyr-Bold'), local('HelveticaNeueCyr-Bold'),
		url('../fonts/Helvetica-Neue/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Helvetica-Neue/HelveticaNeueCyr-Bold.woff') format('woff'),
		url('../fonts/Helvetica-Neue/HelveticaNeueCyr-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: MonumentExtended-Regular;
	src: url('../fonts/Monument-Extended/MonumentExtended-Regular.eot');
	src: local('Monument'), local('MonumentExtended-Regular'),
		url('../fonts/Monument-Extended/MonumentExtended-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Monument-Extended/MonumentExtended-Regular.woff') format('woff'),
		url('../fonts/Monument-Extended/MonumentExtended-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: MonumentExtended-Light;
	src: url('../fonts/Monument-Extended/MonumentExtended-Light.eot');
	src: local('Monument'), local('MonumentExtended-Light'),
		url('../fonts/Monument-Extended/MonumentExtended-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Monument-Extended/MonumentExtended-Light.woff') format('woff'),
		url('../fonts/Monument-Extended/MonumentExtended-Light.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: Monument-Extended-Bold;
	src: url('../fonts/Monument-Extended/MonumentExtended-Bold.eot');
	src: local('Monument'), local('Monument-Extended-Bold'),
		url('../fonts/Monument-Extended/MonumentExtended-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Monument-Extended/MonumentExtended-Bold.woff') format('woff'),
		url('../fonts/Monument-Extended/MonumentExtended-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: MonumentExtended-Ultrabold;
	src: url('../fonts/Monument-Extended/MonumentExtended-Ultrabold.eot');
	src: local('Monument'), local('MonumentExtended-Ultrabold'),
		url('../fonts/Monument-Extended/MonumentExtended-Ultrabold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Monument-Extended/MonumentExtended-Ultrabold.woff') format('woff'),
		url('../fonts/Monument-Extended/MonumentExtended-Ultrabold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

body{
	font-family: HelveticaNeueCyr-Medium;
	font-size: 18px;
	line-height: 18px;
	color: #010101;
}

body.no_scroll{
	overflow: hidden;
}

section{
	// height: 100vh;
	position: relative;
}

a{
	color: #010101;
	transition: 0.3s;
	&:hover{
		color: #010101;
	}
	&:not(:hover){
		transition: 0.3s;
	}
}

h1{
	font-family: Monument-Extended-Bold;
	font-size: 48px;
	line-height: 46px;
	color: #000000;
	@media (max-width: 767.98px) {
		font-size: 24px;
	}
}

h2{
	font-family: Monument-Extended-Bold;
	font-size: 36px;
	line-height: 96%;
	@media (max-width: 767.98px) {
		font-size: 24px;
	}
}

.container{
	@media (min-width: 1200px){
		max-width: 1200px;
	}
}

header{
	.header{
		padding-top: 20px;
		.logo{
			img{
				@media (max-width: 767.98px) {
					max-width: 93px;
				}
			}
		}
	}
	.menu{
		@media (max-width: 991.98px) {
			display: none;
		}
		.nav-link{
			font-family: Monument-Extended-Bold;
			font-size: 18px;
			line-height: 96%;
			margin-right: 49px;
			padding: 0;
			&:last-child{
				margin-right: 0px;
			}
		}
	}
	.mob_menu{
		display: none;
		a{
			font-family: Monument-Extended-Bold;
			font-size: 25px;
			line-height: 96%;
			text-transform: uppercase;
			text-decoration: none;
			@media (max-width: 767.98px) {
				font-size: 16px;
			}
		}
		@media (max-width: 991.98px) {
			display: block;
		}
	}
	.mob_menu_block{
		display: none;
		position: fixed;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: calc(100% - 71px);
	    margin-top: 85px;
	    background: #fff;
	    z-index: 999;
	    ul{
	    	padding: 0;
	    	margin-top: 95px;
	    	list-style: none;
	    	text-align: center;
	    	@media (max-width: 767.98px) {
				margin-top:63px;
			}
	    	li{
	    		margin-bottom: 70px;
	    		@media (max-width: 767.98px) {
					margin-bottom: 65px;
				}
	    		a{
	    			font-family: Monument-Extended-Bold;
					font-size: 25px;
					line-height: 96%;
					@media (max-width: 767.98px) {
						font-size: 16px;
					}					
	    		}
	    	}
	    }
	}
}

#main{
	h1{
		position: absolute;
		top: 0;
		z-index: 1;
		@media (max-width: 991.98px) {
			position: relative;
			font-size: 44px;
			line-height: 96%;
			margin-bottom: 52px;
			br{
				display: none;
			}
		}
		@media (max-width: 767.98px) {
			font-size: 24px;
		}
	}
	.main{
		max-width: 971px;
		margin: auto;
		margin-top: 126px;
		position: relative;
		@media (max-width: 991.98px) {
			margin-top: 106px;
			padding: 0 90px;
		}
		@media (max-width: 767.98px) {
			padding: 0px;
		}
		.info_block{
			.citate{
				font-family: MonumentExtended-Regular;
				font-size: 18px;
				line-height: 96%;
				margin-bottom: 81px;
				@media (max-width: 991.98px) {
					margin-bottom: 57px;
				}
				@media (max-width: 767.98px) {
					font-size: 14px;
				}
			}
			.tel{
				font-size: 18px;
				line-height: 18px;
				font-weight: 600;
				margin-bottom: 22px;
				@media (max-width: 767.98px) {
					font-size: 14px;
				}
			}
			.citate2{
				font-size: 18px;
				line-height: 18px;
				font-weight: 600;
				margin-bottom: 25px;
				@media (max-width: 767.98px) {
					font-size: 14px;
				}
			}
		}
		.video{
			video{
				max-width: 768px;
				@media (max-width: 991.98px) {
					width: 100%;
				}
			}
			@media (max-width: 991.98px) {
				margin-top: 87px;
			}
			@media (max-width: 767.98px) {
				margin-top: 45px;
				padding-bottom: 60px;
			}
			img{
				width: 107%;
			}
		}	
	}
}

#brands{
	.brands_block{
		padding: 70px 101px;
		@media (max-width: 991.98px) {
			padding: 103px 0px;
		}
		@media (max-width: 767.98px) {
			padding: 76px 0px;
		}
		h2{
			margin-bottom: 58px;
			@media (max-width: 991.98px) {
				margin-bottom: 51px;
			}
			@media (max-width: 767.98px) {
				margin-bottom: 58px;
			}
		}
		.brands_items{
			padding-left: 100px;
			@media (max-width: 991.98px) {
				padding-left: 92px;
			}
			@media (max-width: 767.98px) {
				padding-left: 47px;
			}
			.col-lg-6:nth-child(even){
				.brand{
					margin: 0 0 0 auto;
					margin-bottom: 67px;
					@media (max-width: 991.98px) {
						margin-bottom: 40px;
					}
					@media (max-width: 767.98px) {
						margin-bottom: 78px;
						margin: 0;
					}
				}
			}
		}
		// .brands_items.mob{
		// 	display: none;
		// 	@media (max-width: 767.98px) {
		// 		display: block;
		// 	}
		// }
		.brand{
			margin-bottom: 67px;
			max-width: 370px;
			@media (max-width: 991.98px) {
				max-width: 243px;
				margin-bottom: 40px;
			}
			@media (max-width: 767.98px) {
				max-width: 243px;
				margin-bottom: 78px;
			}
			img{
				width: 100%;
			    max-width: 370px;
			    max-height: 370px;
			    object-fit: cover;
			    @media (max-width: 991.98px) {
					max-width: 243px;
			   		max-height: 243px;
				}
			}
			.name{
				font-family: Monument-Extended-Bold;
				font-size: 18px;
				line-height: 17px;
				letter-spacing: 0.05em;
				font-weight: bold;
				margin-top: 24px;
			}
			p{
				font-size: 14px;
				line-height: 22px;
				color: #787878;
			}
		}
	}
}


#about{
	.about_block{
		padding: 70px 100px;
		@media (max-width: 991.98px) {
			padding: 103px 0px;
		}
		@media (max-width: 767.98px) {
			padding: 76px 0px;
		}
		h2{
			margin-bottom: 56px;
		}
		.about_citate{
			font-family: HelveticaNeueCyr-Bold;
			font-size: 24px;
			line-height: 24px;
			@media (max-width: 767.98px) {
				font-size: 15px;
				line-height: 15px;
			}
		}
		.about_text{
			margin: 116px 0 0px 100px;
			@media (max-width: 767.98px) {
				margin: 116px 0 0px 62px;
			}
			.col-lg-6:nth-child(2) {
				.item_text{
					margin-left: 50px;
					@media (max-width: 767.98px) {
						margin-left: 0px;
					}
				}
			}
			.item_text{
				font-size: 20px;
				line-height: 23px;
				font-weight: 600;
				@media (max-width: 767.98px) {
					font-size: 13px;
					line-height: 115.6%;
					margin-bottom: 47px;
				}
			}
		}
	}
}


#blog{
	.blog_block{
		padding: 70px 100px;
		@media (max-width: 991.98px) {
			padding: 103px 0px;
		}
		h2{
			margin-bottom: 140px;
			@media (max-width: 991.98px) {
				margin-bottom: 53px;
    			padding-right: 40%;
			}
			@media (max-width: 767.98px) {
				padding-right: 20%;
			}
		}
		.block_video{
			max-width: 670px;
			@media (max-width: 991.98px) {
				max-width: 470px;
			}
			video{
				width: 100%;
			    object-fit: cover;
			}
			.name{
				font-family: HelveticaNeueCyr-Bold;
				font-weight: bold;
				font-size: 24px;
				line-height: 115.6%;
				text-transform: uppercase;	
				margin-top: 41px;
				@media (max-width: 991.98px) {
					margin-top: 24px;
					font-size: 22px;
				}
				@media (max-width: 767.98px) {
					margin-top: 20px;
					font-size: 15px;
				}							
			}
		}
		.block{
			margin-top: 97px;
			@media (max-width: 991.98px) {
				margin-top: 46px;
			}
		}
	}
	.fon{
		position: absolute;
		bottom: 0;
		background: #EDEDED;
		width: 100%;
		min-height: 721px;
		z-index: -1;
		@media (max-width: 991.98px) {
			min-height: 560px;
		}
	}
}

#services{
	.services_block{
		padding: 67px 100px;
		@media (max-width: 991.98px) {
			padding: 105px 0px;
		}
		@media (max-width: 767.98px) {
			padding: 77px 0px;
		}
		h2{
			text-transform: uppercase;
			margin-bottom: 58px;
			@media (max-width: 991.98px) {
				margin-bottom: 77px;
			}
			@media (max-width: 767.98px) {
				margin-bottom: 54px;
				font-size: 23px;
			}
		}
		.citate{
			@media (max-width: 991.98px) {
				font-size: 15px;
				line-height: 15px;
			}
			.service{
				padding-left: 20px;
				margin-bottom: 62px;
				@media (max-width: 991.98px) {
					padding-left: 0px;
				}
				h3{
					font-family: HelveticaNeueCyr-Bold;
					font-weight: bold;
					font-size: 24px;
					line-height: 24px;
					margin-bottom: 24px;
					text-transform: uppercase;
					@media (max-width: 767.98px) {
						font-size: 15px;
						margin-bottom: 28px;
					}
				}
				.list{
					padding-left: 1px;
					padding-left: 81px;
					@media (max-width: 767.98px) {
						padding-left: 47px;
					}
					p{
						font-family: HelveticaNeueCyr-Medium;
						font-size: 14px;
						line-height: 22px;
						color: #787878;
					}
				}
				ul{
					padding: 0;
					list-style: none;
					li{
						font-family: HelveticaNeueCyr-Medium;
						font-size: 14px;
						line-height: 22px;
						color: #787878;						
					}
				}
			}
		}
	}
}

#contacts{
	.contacts_block{
		padding: 67px 100px;
		@media (max-width: 991.98px) {
			padding: 104px 0px;
		}
		@media (max-width: 767.98px) {
			padding: 76px 0px;
		}
		h2{
			text-transform: uppercase;
			margin-bottom: 58px;
			@media (max-width: 767.98px) {
				margin-bottom: 25px;
			}
		}
		.citate{
			font-family: HelveticaNeueCyr-Bold;
			font-weight: bold;
			font-size: 24px;
			line-height: 24px;
			text-transform: uppercase;
			@media (max-width: 991.98px) {
				font-size: 24px;
				line-height: 24px;
			}
			@media (max-width: 767.98px) {
				font-size: 15px;
				line-height: 15px;
			}
		}
		.adress_block{
		    margin-top: 95px;
		    padding: 0px 100px;
		    @media (max-width: 991.98px) {
				margin-top: 71px;
    			padding: 0px 90px;
			}
			@media (max-width: 767.98px) {
				margin-top: 51px;
    			padding-left: 48px;
			}
		  //   .col-lg-8:nth-child(2){
				// .adress_item{
				// 	padding-left: 33px;
				// }
		  //   }
		  //   .col-lg-4:nth-child(3){
				// .slogan{
				// 	padding-left: 33px;
				// }
		  //   }
			.adress_item{
				margin-bottom: 53px;
				@media (max-width: 767.98px) {
					margin-bottom: 25px;
				}
				.name{
					font-family: Monument-Extended-Bold;
					font-weight: bold;
					font-size: 14px;
					line-height: 187.2%;
					color: #787878;
					margin-bottom: 12px;
					@media (max-width: 767.98px) {
						font-size: 12px;
						line-height: 187.2%;
						margin-bottom: 5px;
					}
				}
				.desc{
					font-weight: 600;
					font-size: 14px;
					line-height: 17px;
					@media (max-width: 767.98px) {
						font-size: 12px;
						line-height: 118.56%;
					}
				}
				.slogan{
					font-size: 14px;
					line-height: 13px;
					font-family: MonumentExtended-Light;
					@media (max-width: 991.98px) {
						margin-top: 49px;
					}
				}
			}
		}
	}
}

#brand{
	.brand_block{
		padding: 112px 100px;
		@media (max-width: 991.98px) {
			padding: 101px 0px;
		}
		@media (max-width: 767.98px) {
			padding: 57px 0px;
		}
		h2{
			font-family: Monument-Extended-Bold;
			font-size: 96px;
			line-height: 92px;	
			position: relative;
    		z-index: 1;	
    		@media (max-width: 991.98px) {
				font-size: 64px;
				line-height: 96%;
			}
			@media (max-width: 767.98px) {
				font-size: 30px;
				line-height: 96%;
			}	
		}
		.img_right{
			margin-top: -47px;
			@media (max-width: 991.98px) {
				margin-top: -100px;
			}
			@media (max-width: 767.98px) {
				margin-top: -23px;
			}
			img{
				width: 100%;
				@media (max-width: 991.98px) {
					max-width: 373px;
				}
				@media (max-width: 767.98px) {
					max-width: 197px;
				}
			}
		}
		.text_block{
			margin-top: 218px;
			@media (max-width: 991.98px) {
				margin-top: 155px;
			}
			@media (max-width: 767.98px) {
				margin-top: 120px;
			}
			.desc{
				padding-left: 100px;
				font-size: 20px;
				line-height: 23px;	
				font-weight: 600;	
				@media (max-width: 991.98px) {
					padding-left: 0px;
					font-size: 17px;
					line-height: 115.6%;
				}
				@media (max-width: 767.98px) {
					padding-left: 41px;
					margin-bottom: 87px;
					font-size: 13px;
					line-height: 115.6%;
				}		
			}
			.col-lg-6:last-child{
				.desc{
					margin-bottom: 0px;
				}
			}
		}
		.img_left{
			margin-top: 168px;
			@media (max-width: 991.98px) {
				margin-top: 196px;
			}
			@media (max-width: 767.98px) {
				margin-top: 56px;
			}
			img{
				width: 100%;
				@media (max-width: 991.98px) {
					max-width: 510px;
				}
				@media (max-width: 767.98px) {
					max-width: 247px;
				}
			}
		}
		.our_brands{
			margin-top: 322px;
			@media (max-width: 991.98px) {
				margin-top: 239px;
			}
			@media (max-width: 767.98px) {
				margin-top: 108px;
			}
			.name{
				font-family: Monument-Extended-Bold;
				font-weight: bold;
				font-size: 35px;
				line-height: 96%;	
				margin-bottom: 191px;	
				@media (max-width: 767.98px) {
					font-size: 24px;
					margin-bottom: 73px;
				}		
			}
			.col-lg-6:nth-child(2) .name{
				margin-bottom: 71px;
				@media (max-width: 991.98px) {
					margin-bottom: 76px;
					margin-top: 177px;
				}
				@media (max-width: 767.98px) {
					margin-bottom: 58px;
					margin-top: 84px;
				}
			}
			.appointment{
				font-family: HelveticaNeueCyr-Bold;
				font-size: 24px;
				line-height: 24px;
				@media (max-width: 767.98px) {
					font-size: 14px;
					line-height: 14px;
				}
			}
			.brands{
				padding-left: 102px;
				@media (max-width: 767.98px) {
					padding-left: 46px;
				}
				.brand{
					margin-bottom: 47px;
					@media (max-width: 991.98px) {
						margin-bottom: 69px;
					}
					@media (max-width: 767.98px) {
						margin-bottom: 76px;
					}
					.brand_name{
						font-family: Monument-Extended-Bold;
						font-weight: bold;
						font-size: 18px;
						line-height: 17px;
						letter-spacing: 0.05em;
					}
					p{
						font-size: 14px;
						font-weight: 600px;
						line-height: 22px;
						color: #787878;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}





#columns {
	// column-width: 420px;
	// column-gap: 31px;
	@media (max-width: 991.98px) {
		// column-width: 320px;
	}
}
.women.desc{
	margin-bottom: 59px;
	@media (max-width: 767.98px) {
		display: none;
	}
}
.women.mob{
	margin-bottom: 59px;
	display: none;
	@media (max-width: 767.98px) {
		display: block;
	}
}
#blog .blog_block .men h2{
	margin-bottom: 125px;
	@media (max-width: 991.98px) {
		margin-bottom: 74px;
	}
}

div#columns figure {
  transition: opacity .4s ease-in-out;
  // display: inline-block;
  // column-break-inside: avoid;
      margin-bottom: 62px;
}
div#columns .col-lg-6:first-child{
	margin-top: 188px;
	@media (max-width: 767.98px) {
		margin-top: 0px;
	}
}
// div#columns figure:nth-child(4){
// 	visibility: hidden;
// 	@media (max-width: 767.98px) {
// 		display: none;
// 	}
// }
.men div#columns figure:first-child{
	margin-top: 0px;
}
.men #columns figure {
    margin-bottom: 0px;
}
div#columns figure.hide{
  display: none;
}

div#columns figure img {
  width: 100%; 
  height: auto;
  padding-bottom: 15px;
  margin-bottom: 5px;
}

div#columns figure figcaption {
	font-family: HelveticaNeueCyr-Bold;
	font-weight: bold;
	font-size: 24px;
	line-height: 115.6%;
	text-transform: uppercase;
	margin-top: 15px;
	@media (max-width: 991.98px) {
		margin-top: 24px;
		font-size: 22px;
	}
	@media (max-width: 767.98px) {
		margin-top: 0px;
		font-size: 15px;
	}
}

div#columns small { 
  font-size: 1rem;
  float: right; 
  text-transform: uppercase;
  color: #aaa;
} 

div#columns small a { 
  color: #666; 
  text-decoration: none; 
  transition: .4s color;
}

/*div#columns:hover figure:not(:hover) {
  opacity: 0.4;
}*/

@media screen and (max-width: 575.98px) { 
  // #columns { column-gap: 0px; }
  #columns figure { width: 100%; }
}